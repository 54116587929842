import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import { fetchProfileFromBungie } from "@/game-destiny2/fetches/fetch-profile.mjs";
import { upsertBungieProfile } from "@/game-destiny2/fetches/post-upsert-user-profile.mjs";
import { addProfile } from "@/game-destiny2/utils/actions.mjs";
import { oauthTokenHandler } from "@/game-destiny2/utils/auth-token-handler.mjs";
import { CLIENT_TYPE } from "@/game-destiny2/utils/redirect-to-bungie.mjs";
import type { BungieToken } from "@/game-destiny2/utils/token-model.mjs";
import globals from "@/util/global-whitelist.mjs";

export default async function fetchData(_, searchParams) {
  const isFromApp = searchParams.get("client_type") === CLIENT_TYPE.app;
  if (isFromApp && !IS_APP) {
    globals.open(
      `blitz://open-url?link=/bungie-oauth-redirect${encodeURIComponent(
        `?${searchParams.toString()}`,
      )}`,
    );
    return;
  }

  const token = {} as BungieToken;
  searchParams.forEach((value, key) => {
    token[key] = value;
  });

  // TODO: error handling
  const bungieId = token.bungie_uid;
  if (!token.token || !bungieId) return;

  let userProfile = readState.settings.loggedInAccounts.destiny2[bungieId];
  if (!userProfile) {
    userProfile = await fetchProfileFromBungie(bungieId);
    userProfile = await upsertBungieProfile(userProfile);
  }

  if (userProfile && !(userProfile instanceof Error)) {
    addProfile(userProfile);
    return oauthTokenHandler.setToken(bungieId, token);
  }
}
